<template>
  <div>
    <a-card :bordered="false">
      <div class="queryView">
        <div class="queryView-conditions">
          <a-form layout="inline" class="form-label-4" :form="form">
            <a-form-item label="区域">
              <a-select
                class="form-control"
                :options="sybList"
                v-decorator="['syb']"
                placeholder="请选择"
                allowClear
              ></a-select>
            </a-form-item>
            <a-form-item label="项目">
              <a-select-pro
                class="form-control"
                v-decorator="['projectCode']"
                placeholder="请选择"
                allowClear
                showSearch
                :options="projectList"
                :dropdownMatchSelectWidth="false"
              ></a-select-pro>
            </a-form-item>
            <a-form-item label="进出方向">
              <a-select
                class="form-control"
                v-decorator="['entrance']"
                placeholder="请选择"
                allowClear
                :options="typesMap.entrance"
              ></a-select>
            </a-form-item>
            <a-form-item label="状态">
              <a-select
                class="form-control"
                v-decorator="['status']"
                placeholder="请选择"
                allowClear
                :options="typesMap.status"
              ></a-select>
            </a-form-item>
            <a-form-item label="车型">
              <a-select
                class="form-control"
                v-decorator="['vehicleType']"
                placeholder="请选择"
                allowClear
                :options="newVehicleTypeList"
              ></a-select>
            </a-form-item>
            <a-form-item label="材料">
              <a-select
                class="form-control"
                :options="materialTypeList"
                v-decorator="['materialType']"
                placeholder="请选择"
                allowClear
              ></a-select>
            </a-form-item>

            <a-form-item label="车牌号">
              <a-input class="form-control" v-decorator="['plateNo']" placeholder="请输入"></a-input>
            </a-form-item>
            <a-form-item label="过车时间">
              <a-range-picker v-decorator="['passTime']" />
            </a-form-item>
            <a-form-item label="登记人">
              <a-input v-decorator="['operatorName']" placeholder="请输入" />
            </a-form-item>
            <a-form-item label="登记时间">
              <a-range-picker v-decorator="['operatorTime']" />
            </a-form-item>
            <!-- <a-form-item label="预处理人">
              <a-select
                class="form-control"
                v-decorator="['pretreatmentCode']"
                placeholder="请选择"
                allowClear
                :options="pretreatmentList"
              />
            </a-form-item> -->
            <a-form-item>
              <div class="condition-btns">
                <a-button type="primary" @click="doQuery(1)">查询</a-button>
                <a-button type="default" @click="resetAndQuery">重置</a-button>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div class="queryView-actions">
          <a-button icon="export" type="primary" @click="exportExcel">导出</a-button>
        </div>
        <div class="queryView-main">
          <a-table
            class="queryView-table"
            :rowKey="$useRowIndex"
            bordered
            :loading="loading"
            :columns="tableColumns"
            :dataSource="dataSource"
            :pagination="false"
            size="middle"
            @change="tableChange"
          ></a-table>
          <base-pagination
            :totalCount.sync="totalCount"
            :pageNo.sync="pageNo"
            :pageSize.sync="pageSize"
            @change="pageChange"
            size="small"
          />
        </div>
      </div>
    </a-card>
    <a-modal :title="modal.title" v-model="modal.visible" class="modal-scroll full-screen" @ok="handleOk">
      <div class="flex">
        <div class="mr-5" v-for="(item, index) in modal.data.images" :key="index">
          <auto-img
            :src="item"
            style="width: 300px; height: 300px"
            :preview="{ options: { initialViewIndex: index }, images: modal.data.images }"
          ></auto-img>
        </div>
      </div>
      <div class="mt-10">
        <a-form class="flex-form form-label-4 a-form-mb-2" :form="modal.form">
          <a-form-item label="设备名称">{{ modal.data.cameraIndexName }}</a-form-item>
          <a-form-item label="进出方向">
            <a-tag color="purple" v-if="modal.data.entrance === '0'">进</a-tag>
            <a-tag color="blue" v-if="modal.data.entrance === '1'">出</a-tag>
          </a-form-item>
          <a-form-item label="车牌号">
            <a-input
              class="form-control"
              :disabled="modal.type === 'CHECK'"
              @keyup.stop
              v-decorator="[
                'plateNo',
                { rules: [{ required: true, message: '请输入' }, { validator: isVehicleNumber }] },
              ]"
            />
            <span
              class="ml-2 text-sm text-red-500"
              v-if="isSybDismatchPlateNo(modal.data.sybName, modal.form.getFieldValue('plateNo'))"
            >
              <a-icon type="info-circle"></a-icon>
              车牌省份不匹配
            </span>
          </a-form-item>
          <a-form-item label="过车时间">{{ modal.data.passTime }}</a-form-item>
          <a-form-item label="推送时间">{{ modal.data.pushTime }}</a-form-item>
          <a-form-item label="处理状态" v-if="modal.data.status === 2">
            <span
              :class="{
                'text-gray-400': modal.data.status === 1,
                'text-green-400': modal.data.status === 2,
              }"
            >
              {{ getTypeName(modal.data.status, typesMap.status) }}
            </span>
          </a-form-item>
          <a-form-item label="处理人" v-if="modal.data.status === 2">{{ modal.data.operatorName }}</a-form-item>
          <a-form-item label="处理时间" v-if="modal.data.status === 2">{{ modal.data.operatorTime }}</a-form-item>
          <a-form-item label="处理记录">
            <a-table
              :dataSource="modal.data.logVoList"
              :pagination="false"
              size="small"
              v-if="modal.data.logVoList && modal.data.logVoList.length > 1"
              style="width: 400px"
            >
              <a-table-column title="处理人" dataIndex="operatorName" align="center"></a-table-column>
              <a-table-column title="处理时间" dataIndex="operatorTime" align="center"></a-table-column>
              <a-table-column title="材料类别" dataIndex="materialTypeName" align="center"></a-table-column>
            </a-table>
          </a-form-item>
          <a-form-item label="出场检查"><a @click="checkOutRecord(modal.data)">查看</a></a-form-item>

          <div class="flex items-center h-10 gap-2">
            <div class="w-24">
              <span v-if="modal.data.cameraIndexName">
                {{ modal.data.cameraIndexName.split('_').slice(-1)[0] }}摄像头：
              </span>
            </div>
            <div class="flex gap-2">
              <a-button @click="openPlayback(modal.data.cameraIndexCode, modal.data.passTime, 10)">
                播放前后10秒
              </a-button>
              <a-button @click="openPlayback(modal.data.cameraIndexCode, modal.data.passTime, 30)">
                播放前后30秒
              </a-button>
              <a-button @click="openPlayback(modal.data.cameraIndexCode, modal.data.passTime, 60)">
                播放前后60秒
              </a-button>
            </div>
          </div>
          <div v-if="!modal.data.otherCameraList">
            <a @click="queryOtherCamera(modal.data)">
              查看所有摄像头
              <a-icon type="arrow-down"></a-icon>
            </a>
          </div>
          <div class="flex items-center h-10 gap-2" v-for="(item, index) in modal.data.otherCameraList" :key="index">
            <div class="w-24">{{ item.cameraIndexName.split('_').slice(-1)[0] }}摄像头：</div>
            <div class="flex gap-2">
              <a-button @click="openPlayback(item.cameraIndexCode, modal.data.passTime, 10)">播放前后10秒</a-button>
              <a-button @click="openPlayback(item.cameraIndexCode, modal.data.passTime, 30)">播放前后30秒</a-button>
              <a-button @click="openPlayback(item.cameraIndexCode, modal.data.passTime, 60)">播放前后60秒</a-button>
            </div>
          </div>
          <div class="text-gray-400">
            需要先下载并安装录像播放插件
            <a @click="downloadPlugin">点击下载</a>
          </div>
          <div
            v-show="
              modal.type === 'DEAL' || (modal.type === 'CHECK' && modal.data.status === 2) || modal.data.status === 5
            "
          >
            <a-form-item label="自动流转" v-if="modal.type === 'DEAL'">
              <a-switch v-model="autoNext"></a-switch>
            </a-form-item>

            <a-form-item label="车型">
              <a-radio-group
                :disabled="modal.type === 'CHECK'"
                v-decorator="['vehicleType', { rules: [{ required: true, message: '请选择' }] }]"
              >
                <a-radio v-for="(item, index) in newVehicleTypeList" :key="index" :value="item.value">
                  {{ item.label }}
                  <span v-if="item.shortCut">(快捷键{{ item.shortCut }})</span>
                </a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="材料" v-show="getVehicleEnable(modal.form.getFieldValue('vehicleType'))">
              <a-radio-group
                class="leading-10"
                :disabled="modal.type === 'CHECK'"
                v-decorator="[
                  'materialType',
                  {
                    rules: [{ required: getVehicleEnable(modal.form.getFieldValue('vehicleType')), message: '请选择' }],
                  },
                ]"
              >
                <a-radio v-for="(item, index) in materialTypeList" :key="index" :value="item.value">
                  {{ item.label }}
                  <span v-if="item.shortCut">(快捷键{{ item.shortCut }})</span>
                </a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="挂牌阻碍">
              <a-radio-group :disabled="modal.type === 'CHECK'" v-decorator="['hinderType']">
                <a-radio v-for="(item, index) in hinderTypeList" :key="index" :value="item.value">
                  {{ item.label }}
                </a-radio>
              </a-radio-group>
            </a-form-item>
          </div>
        </a-form>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { queryViewMixin } from '@/utils/mixin'
import ASelectPro from '@/components/SelectPro.vue'
import {
  getProjectList,
  getNewVehicleTypeList,
  getMaterialTypeList,
  getSybList,
  getHinderTypeList,
  getPretreatmentList,
} from '@/service/getData/aie'
import { getTypeName } from '@/utils'
import _ from 'lodash'
import moment from 'moment'
import { isVehicleNumber } from '@/utils/antd'
import { download } from '@/service/file'

export default {
  components: {
    ASelectPro,
  },
  mixins: [queryViewMixin],

  data() {
    const tableColumns = [
      {
        title: '车牌号',
        width: 90,
        align: 'center',
        dataIndex: 'plateNo',
      },
      {
        title: '区域',
        width: 130,
        align: 'center',
        dataIndex: 'sybName',
      },
      {
        title: '项目编码',
        width: 80,
        align: 'center',
        dataIndex: 'projectCode',
      },
      {
        title: '设备名称',
        width: '25%',
        align: 'center',
        dataIndex: 'cameraIndexName',
      },
      {
        title: '过车时间',
        width: '12%',
        align: 'center',
        dataIndex: 'passTime',
        sorter: true,
        sortOrder: 'ascend',
      },
      {
        title: '进出方向',
        width: 80,
        align: 'center',
        dataIndex: 'entrance',
        customRender: (text, record) => {
          return (
            <div>
              {text === '0' && <a-tag color="purple">进</a-tag>}
              {text === '1' && <a-tag color="blue">出</a-tag>}
            </div>
          )
        },
      },
      {
        title: '车型',
        width: '8%',
        align: 'center',
        dataIndex: 'newVehicleTypeName',
      },
      {
        title: '材料',
        width: '8%',
        align: 'center',
        dataIndex: 'materialTypeName',
      },
      {
        title: '状态',
        width: 80,
        align: 'center',
        dataIndex: 'status',
        customRender: (text, record) => {
          const color = {
            'text-gray-400': text === 1,
            'text-green-400': text === 2 || text === 5,
          }
          return (
            <div>
              <div class={color}>{this.getTypeName(text, this.typesMap.status)}</div>
            </div>
          )
        },
      },
      {
        title: '登记人',
        dataIndex: 'operatorName',
        width: 80,
      },
      {
        title: '登记时间',
        width: '12%',
        align: 'center',
        dataIndex: 'operatorTime',
        sorter: true,
      },
      {
        title: '推送时间',
        width: '12%',
        align: 'center',
        dataIndex: 'pushTime',
      },
      // {
      //   title: '预处理人',
      //   width: '8%',
      //   align: 'center',
      //   dataIndex: 'pretreatmentUserName',
      // },

      {
        title: '操作',
        width: 120,
        align: 'center',
        dataIndex: 'online',
        customRender: (text, record) => {
          return (
            <div class="flex justify-center gap-4">
              <a
                onClick={() => {
                  this.checkRow(record)
                }}
              >
                查看
              </a>
              <a
                v-action="GCJL_EDIT"
                onClick={() => {
                  this.dealRow(record)
                }}
              >
                去登记
              </a>
            </div>
          )
        },
      },
    ]
    return {
      form: this.$form.createForm(this),
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      loading: false,
      tableColumns: tableColumns,
      dataSource: [],
      projectList: [],
      newVehicleTypeList: [],
      materialTypeList: [],
      hinderTypeList: [],
      sybList: [],
      pretreatmentList: [],
      autoNext: false,
      modal: {
        visible: false,
        form: this.$form.createForm(this),
        title: '',
        data: {},
      },
      confirming: false, // 确认弹窗
    }
  },
  computed: {
    typesMap() {
      return {
        entrance: [
          {
            label: '进',
            value: '0',
          },
          {
            label: '出',
            value: '1',
          },
        ],
        status: [
          {
            label: '未登记',
            value: 0,
          },
          // {
          //   label: '登记中',
          //   value: 1,
          // },
          {
            label: '人工登记',
            value: 2,
          },
          // {
          //   label: '已审核',
          //   value: 3,
          // },
          // {
          //   label: '无需登记',
          //   value: 4,
          // },
          {
            label: '系统自动登记',
            value: 5,
          },
        ],
      }
    },
  },
  methods: {
    isSybDismatchPlateNo(sybName = '', plateNo = '') {
      let provinceShort = ''
      if (sybName) {
        if (sybName.match('重庆')) {
          provinceShort = '渝'
        }
        if (sybName.match('西安')) {
          provinceShort = '陕'
        }
        if (sybName.match('昆明')) {
          provinceShort = '云'
        }
        if (sybName.match('贵阳')) {
          provinceShort = '贵'
        }
        if (sybName.match('郑州')) {
          provinceShort = '豫'
        }
        if (sybName.match('武汉')) {
          provinceShort = '鄂'
        }
        if (sybName.match('四川')) {
          provinceShort = '川'
        }
        if (sybName.match('长沙')) {
          provinceShort = '湘'
        }
        if (sybName.match('兰州')) {
          provinceShort = '甘'
        }
        if (sybName.match('银川')) {
          provinceShort = '宁'
        }
        if (sybName.match('柳州')) {
          provinceShort = '桂'
        }
        if (sybName.match('南宁')) {
          provinceShort = '桂'
        }
      }

      if (provinceShort && !plateNo.startsWith(provinceShort)) {
        return true
      }
    },
    getVehicleEnable(vehicleType) {
      const vehicle = this.newVehicleTypeList.find((item) => item.value === vehicleType)?.origin
      return vehicle?.typeValueEnable
    },
    getTypeName,
    getFormatedPageParams(pageNo, pageSize) {
      let params = this.formatTimeRangeParams(this.getPageParams(pageNo, pageSize), 'passTime', {
        format: 'YYYY-MM-DD HH:mm:ss',
      })
      params = this.formatTimeRangeParams(params, 'operatorTime', {
        format: 'YYYY-MM-DD HH:mm:ss',
      })
      return params
    },
    async doQuery(_pageNo = this.pageNo, _pageSize = this.pageSize) {
      this.loading = true
      try {
        const res = await this.$axios.post('/aie/web/truck/details', this.getFormatedPageParams(_pageNo, _pageSize))
        const list = res.data.rows
        const { pageSize, pageNo, totalCount } = res.data
        this.totalCount = totalCount
        this.pageSize = pageSize
        this.pageNo = pageNo
        this.dataSource = list
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    async loadData() {
      Promise.all([
        (async () => {
          const newVehicleTypeList = await getNewVehicleTypeList()
          this.newVehicleTypeList = newVehicleTypeList.map((item) => {
            let shortCut = ''
            if (item.value === 'truck') {
              shortCut = '1'
            }
            if (item.value === 'concreteMixer') {
              shortCut = '2'
            }
            if (item.value === 'vehicle') {
              shortCut = '3'
            }
            if (item.value === 'van') {
              shortCut = '4'
            }
            return {
              ...item,
              shortCut,
            }
          })
        })(),
        (async () => {
          const materialTypeList = await getMaterialTypeList()
          this.materialTypeList = materialTypeList.map((item) => {
            let shortCut = ''
            if (item.value === 'C9999') {
              shortCut = 'q'
            }
            if (item.value === 'OTHER') {
              shortCut = 'w'
            }
            if (item.value === 'C0151') {
              shortCut = 'e'
            }
            return {
              ...item,
              shortCut,
            }
          })
        })(),
        (async () => {
          this.sybList = await getSybList()
        })(),
        (async () => {
          this.hinderTypeList = await getHinderTypeList()
        })(),
        (async () => {
          this.pretreatmentList = await getPretreatmentList()
        })(),
      ])
    },
    checkRow(row) {
      this.resetModal()
      this.modal.title = `过车详情-${row.plateNo}`
      this.modal.visible = true
      this.modal.type = 'CHECK'
      this.modal.data = {
        ...row,
      }
      this.safeSetFieldsValue(this.modal.form, { ...row, vehicleType: row.newVehicleType })
    },
    downloadPlugin() {
      window.open('/hkplayer/bin/VideoWebPlugin.exe')
    },
    openPlayback(cameraIndexCode, passTime, range) {
      const startTime = moment(passTime).subtract(range, 'second').valueOf()
      const endTime = moment(passTime).add(range, 'second').valueOf()

      window.open(
        `/hkplayer/app/hkplayback.html?startTime=${startTime}&endTime=${endTime}&cameraIndexCode=${cameraIndexCode}`
      )
    },
    async dealRow(row) {
      try {
        this.resetModal()
        this.modal.title = `过车详情-${row.plateNo}`
        this.modal.visible = true
        this.modal.type = 'DEAL'
        this.modal.data = {
          ...row,
        }
        this.safeSetFieldsValue(this.modal.form, { ...row, vehicleType: row.newVehicleType })
      } catch (e) {
        this.$message.error(e.message)
      }
    },
    checkOutRecord(row) {
      const { projectCode, passTime, plateNo } = row
      const params = {
        projectCode,
        passDate: moment(passTime).format('YYYY-MM-DD'),
        plateNo,
      }
      let paramsString = ''
      for (const i in params) {
        paramsString += `${i}=${params[i]}&`
      }
      window.open(`/b/m/aie/outRecordList?${paramsString}`)
    },
    async handleOk() {
      const { type } = this.modal
      if (type === 'CHECK') {
        this.modal.visible = false
      }
      if (type === 'DEAL') {
        this.modal.form.validateFields(async (errors, values) => {
          if (!errors) {
            const params = values
            try {
              const { id } = this.modal.data
              // 原始车型 是否为 有材料车型
              const originEnabel = this.getVehicleEnable(this.modal.data.newVehicleType)
              // 修改后车型 是否为 有材料车型
              const enable = this.getVehicleEnable(params.vehicleType)
              // 非 有材料车型
              if (!enable) {
                params.materialType = null
              }
              // console.log(params)
              // 更改了车型
              if (this.modal.data.newVehicleType !== params.vehicleType) {
                //   且 更改过后车型为 非 有材料车型
                if (originEnabel && !enable) {
                  // 校验 原始车型是否有记录
                  const res = await this.$axios.post('/aie/web/check/truck/pass', { id })
                  if (res.data === true) {
                    await new Promise((resolve, reject) => {
                      this.confirming = true
                      this.$confirm({
                        title: '操作确认',
                        content:
                          '当前车辆已有历史登记材料的记录,确认修改成无需登记材料车型？（注：该操作不会修改历史过车记录的车型）',
                        onOk: () => {
                          this.confirming = false
                          resolve()
                        },
                        onCancel: () => {
                          this.confirming = false
                          reject(new Error('E_CANCEL'))
                        },
                      })
                    })
                  }
                }
              }
              await this.$axios.post('/aie/web/update/truck/type', {
                id,
                ...params,
              })
              this.$message.success('操作成功!')
              this.modal.visible = false
              await this.doQuery()
              if (this.autoNext) {
                const list = this.dataSource
                if (list.length === 0) {
                  this.$message.success('已处理完最后一条')
                } else {
                  this.dealRow(list[0])
                }
              }
            } catch (e) {
              console.error(e)
            }
          }
        })
      }
    },

    keyup: _.throttle(function (e) {
      // 添加操作快捷键
      if (this.modal.type === 'DEAL') {
        // 车型快捷键
        const vehicleTypeShortCut = {}
        const materialTypeShortCut = {}
        this.newVehicleTypeList.forEach((item) => {
          if (item.shortCut) {
            vehicleTypeShortCut[item.shortCut] = item.value
          }
        })

        this.materialTypeList.forEach((item) => {
          if (item.shortCut) {
            materialTypeShortCut[item.shortCut] = item.value
          }
        })
        if (e.key in vehicleTypeShortCut) {
          this.safeSetFieldsValue(this.modal.form, {
            vehicleType: vehicleTypeShortCut[e.key],
          })
        }
        if (e.key in materialTypeShortCut) {
          if (this.getVehicleEnable(this.modal.form.getFieldValue('vehicleType'))) {
            this.safeSetFieldsValue(this.modal.form, {
              materialType: materialTypeShortCut[e.key],
            })
          }
        }
        if (e.key === 'Enter' || e.key === ' ') {
          if (!this.confirming) {
            this.handleOk()
          }
          // 阻止浏览器空格选中radio，组织浏览器空格确认 避免误操作
          e.preventDefault()
        }
      }
    }, 100),

    isVehicleNumber,
    async queryOtherCamera(row) {
      const res = await this.$axios.post('/aie/web/video/indexCodes', {
        projectCode: row.projectCode,
        door: row.door,
        entrance: row.entrance,
      })
      const ret = res.data
        .filter((item) => item.entrance === row.entrance)
        .filter((item) => item.cameraIndexCode !== row.cameraIndexCode)
      this.$set(this.modal.data, 'otherCameraList', ret)
    },
    exportExcel() {
      const params = this.getFormatedPageParams(this.pageNo, this.pageSize)
      download('/aie/web/truck/details/export', params)
    },
  },
  destroyed() {
    window.removeEventListener('keyup', this.keyup)
  },
  async mounted() {
    this.loadData()
    this.projectList = await getProjectList()
    const { projectCode, passDate, plateNo, noInitial } = this.$route.query
    if (projectCode) {
      this.form.setFieldsValue({
        projectCode,
      })
    }
    if (passDate) {
      this.form.setFieldsValue({
        passTime: [moment(passDate), moment(passDate)],
      })
    }
    if (plateNo) {
      this.form.setFieldsValue({
        plateNo,
      })
    }
    if (!noInitial) {
      this.form.setFieldsInitialValue({
        status: 0,
      })
    }
    this.doQuery()
    window.addEventListener('keyup', this.keyup)
  },
}
</script>

<style lang="less" scoped></style>
