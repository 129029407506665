const getQuery = url => {
  var q = {}
  var search = url.split('?')[1]
  if (search) {
    search.replace(/([^?&=]+)=([^&]+)/g, (_, k, v) => (q[k] = v))
  }
  return q
}
const promiseTimeout = timeout => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, timeout)
  })
}
const setIntervalImmediate = (fn, timer) => {
  setTimeout(fn)
  return setInterval(fn, timer)
}
/**
 *
 * @param {*} resultFn 结束，一直到这个resultFn值返回true 或者 Promise.resolve()
 * @param {*} max 最长等待时间，ms为0表示不限制最长时间
 * @param {*} interval 检测间隔 ms
 * @param {*} times 尝试次数 为0表示无限次
 */
const waitFor = async (resultFn, { max = 0, interval = 1000, times = 0 } = {}) => {
  let startTime = new Date().getTime()
  let count = 0
  let canceled = false
  const beforeHook = function() {
    console.log(`[waitFor start] ${startTime}`)
  }
  const afterHook = function() {
    const endTime = new Date().getTime()
    console.log(`[waitFor end] ${endTime} ,total:${endTime - startTime}`)
  }
  const loopFn = () => {
    return new Promise((resolve, reject) => {
      if (canceled) {
        reject(new Error('WaitFor Canceled'))
      } else {
        let res = resultFn()
        count++
        if (res instanceof Promise) {
          res
            .then(result => {
              resolve(result)
            })
            .catch(() => {
              nextLoop()
            })
        } else {
          if (res) {
            resolve(res)
          } else {
            nextLoop()
          }
        }
        function nextLoop() {
          let currentTime = new Date().getTime()
          if (max === 0 || currentTime - startTime <= max) {
            if (times === 0 || count <= times) {
              setTimeout(() => {
                resolve(loopFn())
              }, interval)
            } else {
              reject(new Error('E_TIMES_OUT'))
            }
          } else {
            reject(new Error('E_MAX_TIME_EXCEED'))
          }
        }
      }
    })
  }
  const cancel = function() {
    canceled = true
  }
  const promise = async () => {
    beforeHook()
    return loopFn()
      .then(res => {
        afterHook()
        return Promise.resolve(res)
      })
      .catch(e => {
        afterHook()
        return Promise.reject(e)
      })
  }
  return {
    promise,
    cancel,
  }
}
// 标准并加强error的展示内容（非error类型会被包装成error类型）。额外增加 displayMessage 和 code
const enhanceError = function(error, { code = 'E_DEFAULT', message = '' }) {
  if (error instanceof Error) {
    error.code = code
    error.originMesssage = error.message
    error.message = message || error.originMesssage
    return error
  } else {
    let wrappedError = new Error(message)
    wrappedError.code = code
    wrappedError.originMesssage = wrappedError.message
    wrappedError.origin = error
    return wrappedError
  }
}
const getTypeName = (v, map = [], { label = 'label', value = 'value' } = {}) => {
  let name = ''
  try {
    const type = map.find(item => String(item[value]) === String(v))
    if (type) {
      name = type[label]
    }
  } catch (e) {
    console.error(e)
  }
  return name
}

export { enhanceError, waitFor, getQuery, promiseTimeout, setIntervalImmediate, getTypeName }
export * from './antd'
